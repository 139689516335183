import React from "react";

function CPFosters() {
  return (
    <div
      style={{
        padding: "20px",
        textAlign: "center",
        maxWidth: "800px",
        margin: "0 auto",
      }}
    >
      <h2>We LOVE our Students Fosters!</h2>
      <p>
      🐾 Fostering a pet is a rewarding experience—not just for you, but for the
        pet who gets a second chance at finding a loving home. At Paddling Paws,
        we make fostering easy by providing everything you need, from food and
        supplies to vet care. Whether you're fostering for a few weeks or a few
        months, you're giving a dog or cat the love and stability they need to
        thrive. Plus, you'll be part of a community that supports you every step
        of the way. Ready to make a difference? Fostering saves lives—one pet at
        a time. ❤️
      </p>

      <h3>📄 Service / Volunteer Hour Information 📄</h3>
      <p>
        Interested in earning service hours through fostering? We are currently
        working with Panhellenic Philanthropy Guidelines and Cal Poly admins to determine service
        hour credits.
      </p>
      {/* <ul style={{ textAlign: "left" }}>
        <li>🐾 Foster a dog for 1 month = 3 service hours completed</li>
        <li>🐾 Foster a dog for 1 quarter = 5 service hours completed</li>
      </ul> */}
      <p>
        Need more information on service hours? Reach out to us, and we can help
        confirm details with your organization!
      </p>

      <h3>🐶 Common Student FAQ 🐶</h3>

      <ul style={{ textAlign: "left" }}>
        <li>
          <strong>💰 How much does fostering cost?</strong>
          <br /> Absolutely nothing! We provide all the essentials—leashes,
          collars, food, crates, and treats. Just bring the love!
        </li>
        <br />
        <li>
          <strong>
            🐩 What dog or cat will I foster? Do I pick one from your website?
          </strong>
          <br /> We work with you to find an animal that fits your lifestyle,
          schedule, and one you are excited to foster! We usually will select a
          rescue for you from a high-kill shelter (we often work with Kern
          County which is a high-kill shelter in Bakersfield with over 300 dogs
          under their roof, so there are a lot of options, and you will
          definitely be saving a life regardless of what dog we rescue, as they
          do euthanize due to overcrowding on a weekly basis). On occasion, we
          will have an animal already within our rescue that needs a new foster
          or temporary fostering while their current foster is out of town.
        </li>
        <br />
        <li>
          <strong>
            📚 I have a lot of classes—what do I do with the dog while I’m gone?
          </strong>
          <br /> No problem! We instruct all our fosters to crate foster pups
          while they are home alone and we provide a size-appropriate crate for
          every one of our foster pups! Just give them a potty break beforehand,
          and place them in their provided crate before class. We also can
          provide crate training tools and have a guide to crate training if you
          need help!
        </li>
        <br />
        <li>
          <strong>
            👯‍♀️ Do my roommates get volunteer hours too, or just me?
          </strong>
          <br /> Everyone in your household who helps care for the pup gets
          volunteer/service hours!
        </li>
        <br />
        <li>
          <strong>⏳ How long does fostering last?</strong>
          <br /> We work with your schedule! Although we do love our long-term
          fosters, and they are really necessary for us to rescue more pets, we
          are flexible and will work with you to find an option that works for
          everyone!
        </li>
        <br />
        {/* <li>
          <strong>😬 What if I change my mind?</strong>  
          <br /> No worries—if fostering doesn’t work out, we’ll take the pup back. There’s no penalty, you just won’t receive volunteer hours.
        </li>
        <br/> */}
        <li>
          <strong>💗 What do I actually do as a foster?</strong>
          <br /> Provide food, walks, bathroom breaks, training practice, and
          most importantly—love! You’re helping a pup get ready for their
          forever home.
        </li>
        <br />
      </ul>

      <h3>🏡 Pet-Friendly Student Housing 🏡</h3>
      <p>
        <strong>Not sure if your apartment or house allows fostering?</strong>{" "}
        Check with your landlord or property management! Here are some
        student-friendly housing options in SLO that <strong>may</strong> allow
        pets:
      </p>
      <ul style={{ textAlign: "center", listStyleType: "none", padding: 0 }}>
        <li>🐾 Twin Creeks Apartments</li>
        <li>🐾 Vintage at SLO</li>
        <li>🐾 Summit SLO</li>
        <li>🐾 Poly Canyon Village - Cal Poly</li>
        <li>🐾 Foothill Commons</li>
        <li>🐾 Peach Street</li>
        <li>🐾 De Tolosa Ranch Apartments</li>
        <li>🐾 Connect SLO</li>
        <li>🐾 Mustang Village</li>
        <li>🐾 Palomar at SLO</li>
      </ul>
      <p>
        ✅{" "}
        <strong>
          Reach out to your landlord to confirm pet policies before fostering!
        </strong>{" "}
        If you're unsure, we can help you check!
      </p>

      <h3>❓ Have More Questions?</h3>
      <p>Email us! 👉 info@paddlingpaws.org</p>

      <h3>🌟 Ready to Foster? 🌟</h3>
      <p>
        If you're excited to foster and ready to provide a loving home to a pup
        in need, we'd love to hear from you! Fill out our{" "}
        <strong>Foster Interest Form</strong> to get started, and our team will
        reach out with the next steps.
      </p>

      <div
        style={{
          position: "relative",
          width: "100%",
          paddingTop: "150%",
          marginBottom: "20px",
        }}
      >
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdvvirYwfXZ2_hiIYDaVIYOw_hjW5UmHdj31g-_TNvmzpFzyw/viewform?embedded=true"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: "0",
          }}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Foster Interest Form"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
}

export default CPFosters;
