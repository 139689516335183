import React, { useEffect } from 'react';
import './Donate.css';

function Donate() {
    // Load Donorbox script dynamically
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://donorbox.org/widget.js';
        script.async = true;
        script.setAttribute('paypalExpress', 'false');
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="donate-container">
            <h2>Support Paddling Paws</h2>
            <p>
                Your donations help us rescue, rehabilitate, and find loving homes for animals in need. 
                We are a 501(c)(3) nonprofit organization, and your contributions are tax-deductible (EIN 99-4349426). 
                Every little bit helps, and we truly appreciate your generosity!
                <br/>
                <br/>
                Here are a few different ways you can donate!
            </p>

            <div className="donation-options">
                <h2>Ways to Donate</h2>
                <h3>Donate with Card</h3>
                <div className="donorbox-container">
                    <iframe 
                        src="https://donorbox.org/embed/paddlingpaws?language=en-us" 
                        name="donorbox" 
                        allowpaymentrequest="allowpaymentrequest" 
                        seamless="seamless" 
                        frameBorder="0" 
                        scrolling="no" 
                        height="900px" 
                        width="100%" 
                        className="donorbox-iframe" 
                        allow="payment" 
                        title="Donate via Donorbox"
                    ></iframe>
                </div>
                <br/>
                <h3>Donate with Apple Pay, Google Pay, Amazon Pay, Cash App Pay, and more!</h3>
                <p>Note: you must have your Google/Apple Pay account connected to your current browser or phone, and a valid card added to your Apple/Google Wallet for these payment options to show.</p>
                <a 
                    href="https://donorbox.org/paddlingpaws?default_interval=o" 
                    className="donorbox-button"
                >
                    <img src="https://donorbox.org/images/white_logo.svg" alt="Donorbox Logo" />Donate
                </a>
                <br/>
                <h3>Donate with Venmo</h3>
                <p>We now have a Venmo charity account!</p>
                <a 
                    href="https://venmo.com/u/paddlingpaws" 
                    className="donorbox-button"
                >
                Give with Venmo
                </a>
            </div>
        </div>
    );
}

export default Donate;
