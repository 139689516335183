import React from "react";

function Adopt2() {
  return (
    <div
      style={{
        padding: "20px",
        textAlign: "center",
        maxWidth: "1000px",
        margin: "0 auto",
      }}
    >
      <h2>Adopt Your New Best Friend</h2>
      <p>
        Adopting a pet is a life-changing experience—not just for you, but for
        the pet that finds a loving forever home. At Paddling Paws, we are here
        to help you find your perfect match.
      </p>
      <p>
        Whether you have your eye on one of our adoptable pets or are looking
        for a specific type of pet we don’t currently have, we’d love to assist
        you in your search. By filling out our Adoption Application, you can
        either express interest in one of our available animals or let us know
        what you're looking for. We’ll do our best to match you with a pet that
        fits your family and lifestyle.
      </p>
      <h3>Our Adoption Process</h3>
      <p>
        Our adoption process is designed to ensure that both you and your new
        pet are set up for success. Here’s what you can expect when you adopt
        from Paddling Paws:
      </p>
      <ul style={{ textAlign: "left" }}>
        <li>Fill out the <strong>Adoption Application</strong> below.</li>
        <li>
        <strong>Our team will review your application and reach out</strong> to discuss your
          preferences and answer any questions you may have.
        </li>
        <li>
          <strong>If you’re interested in a specific pet</strong>, we’ll schedule a meet-and-greet ASAP
          to introduce you to the animal and see if it’s a good fit. Plan for this being around 30 minutes.
        </li>
        <li>
          After the meet-and-greet you will have  <strong>24 hours to consider adopting the pet</strong>! During this time we will not scheduling any more meet-and-greets for the adoptable rescue pet.
        </li>
        <li>
        If you decide to move forward with the adoption, <strong>we’ll schedule a good time to finalize the
        paperwork and send you home with your new best friend!</strong>
        </li>
      </ul>
      <h3>Our Adoption Fee</h3>
      <p>
        Our adoption fee is $275 for all our dogs. This fee helps cover
        the cost of food, supplies, spaying/neutering, vaccinations, and other medical care your
        pet has received while in our care. It also helps us continue our
        mission of rescuing and rehoming animals in need.
      </p>
      <p>
        Ready to take the next step? Fill out the Adoption Application below,
        and let’s find your new best friend together!
      </p>
      <div
        style={{
          position: "relative",
        //   width: "100%",
        //   paddingTop: "150%",
        //   marginBottom: "20px",
        }}
      >

        <iframe
        id="JotFormIFrame-250038349810151"
        title="Rescue Animal Adoption Application Form"
        onload="window.parent.scrollTo(0,0)"
        allowtransparency="true"
        allow="geolocation; microphone; camera; fullscreen"
        src="https://form.jotform.com/250038349810151"
        frameborder="0"
        style={{
            minWidth: "100%",
            // maxWidth: "100%",
            height: "650px",
            // minHeight: "539px",
            border: "none"
          }}
        // style="min-width:100%;max-width:100%;height:539px;border:none;"
        scrolling="no"
        >
        </iframe>
        <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
        <script>window.jotformEmbedHandler("iframe[id='JotFormIFrame-250038349810151']", "https://form.jotform.com/")</script>
      </div>
    </div>
  );
}

export default Adopt2;