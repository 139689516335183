import React from "react";
import { Helmet } from "react-helmet";

import NWD_logo from "../assets/images/sponsor_logos/NWD_logo.png";
import SilverOak_logo from "../assets/images/sponsor_logos/silveroak.png";
import Masterguard_logo from "../assets/images/sponsor_logos/MasterguardLogo.png";
import Ecobags_logo from "../assets/images/sponsor_logos/ecobags.png";
import NauticalBean_logo from "../assets/images/sponsor_logos/nauticalbean.png";
import LasTablasVet_logo from "../assets/images/sponsor_logos/lastablasvet.png";
import ShellBeachSurf_logo from "../assets/images/sponsor_logos/shellbeachsurfshop.svg";
import BreadBike_logo from "../assets/images/sponsor_logos/bike-grass-big-blue.png";
import Wilderdog_logo from "../assets/images/sponsor_logos/WilderdogLogo.png";
import Pawfy_logo from "../assets/images/sponsor_logos/PawfyLogo.webp";

function Sponsors() {
  const partnerLogos = [
    {
      src: NWD_logo,
      alt: "NWD",
      link: "https://www.nicoleweberdesign.com/",
    },
    {
      src: SilverOak_logo,
      alt: "Silver Oak Construction",
      link: "https://silveroakcon.com/",
    },
    {
      src: Masterguard_logo,
      alt: "Masterguard",
      link: "https://www.amazon.com/stores/page/637AD7AC-2C57-4BD0-BDC5-95462B973357?ref_=cm_sw_r_apin_ast_store_A15P9YHG1NT3TK3BKA22&fbclid=PAZXh0bgNhZW0CMTEAAaYpdYdcQ7-99gsq7dYCosbwVFcFKP41phpjVDB8FVinNhLXMSnzUwwf6D8_aem__DYqL0Ae_GxaAatORxLQqw",
    },
    {
      src: Ecobags_logo,
      alt: "Ecobags US",
      link: "https://www.ecobags.com/",
    },
    {
      src: NauticalBean_logo,
      alt: "Nautical Bean",
      link: "https://nbcentralcoast.com/",
    },
    {
      src: LasTablasVet_logo,
      alt: "Las Tablas Animal Hospital",
      link: "https://lastablasanimalhospital.com/",
    },
    {
      src: ShellBeachSurf_logo,
      alt: "Shell Beach Surf Shop",
      link: "https://shellbeachsurfshop.com/",
    },
    {
      src: BreadBike_logo,
      alt: "Bread Bike Bakery",
      link: "https://www.slobreadbike.com/",
    },
    {
      src: Wilderdog_logo,
      alt: "Wilderdog",
      link: "https://www.wilderdog.com/",
    },
    {
      src: Pawfy_logo,
      alt: "Pawfy",
      link: "https://www.pawfy.com/",
    },

  ];

  return (
    <div style={styles.container}>
      <Helmet>
        <title>Paddling Paws - Partnerships &amp; Collaborations</title>
        <meta
          name="description"
          content="Join us as a business partner or collaborator and help Paddling Paws rescue more animals."
        />
        <meta
          name="keywords"
          content="animal rescue partnerships, nonprofit collaborations, support animals, Paddling Paws partnership"
        />

        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content="Paddling Paws - Partnerships &amp; Collaborations"
        />
        <meta
          property="og:description"
          content="Join us as a business partner or collaborator and help Paddling Paws rescue more animals."
        />
        <meta
          property="og:image"
          content="https://paddlingpaws.org/static/media/paddling_paws_hug_2.75ebef04ccbc4c38c2a8.png"
        />
        <meta
          property="og:url"
          content="https://www.paddlingpaws.org/sponsors"
        />
        <meta property="og:type" content="website" />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Paddling Paws",
            "url": "https://www.paddlingpaws.org",
            "logo": "https://www.paddlingpaws.org/images/logo.png",
            "sameAs": [
              "https://www.instagram.com/paddling.paws",
              "https://www.youtube.com/@paddlingpaws"
            ],
            "contactPoint": {
              "@type": "ContactPoint",
              "email": "info@paddlingpaws.org",
              "contactType": "Customer Service"
            }
          }
          `}
        </script>
      </Helmet>

      <h2 style={styles.title}>Partner With Paddling Paws</h2>
      <p style={styles.text}>
        At Paddling Paws, we strive to create meaningful, long-term relationships
        with businesses and organizations that share our passion for animal
        welfare. By collaborating with us, you’ll be directly supporting our
        efforts to rescue, rehabilitate, and rehome abandoned and abused dogs
        and cats throughout California, while showcasing your brand’s commitment
        to making a positive impact in the community.
      </p>

      <h3 style={styles.subtitle}>Why Collaborate With Us?</h3>
      <ul style={styles.list}>
        <li>
          <strong>Make a tangible difference:</strong> Your partnership enables
          us to provide essential medical care, food, and shelter for animals in
          need.
        </li>
        <li>
          <strong>Build brand visibility:</strong> Gain exposure through our
          community events, social media, and website presence.
        </li>
        <li>
          <strong>Enhance social responsibility:</strong> Align your
          organization with a compassionate cause that resonates with employees
          and customers alike.
        </li>
        <li>
          <strong>Community engagement:</strong> Collaborate on events,
          initiatives, or fundraising campaigns tailored to your business goals.
        </li>
      </ul>

      <h3 style={styles.subtitle}>How We Support Our Partners</h3>
      <ul style={styles.list}>
        <li>
          <strong>Co-branded marketing:</strong> Feature your business on our
          platforms and promotional materials.
        </li>
        <li>
          <strong>Event participation:</strong> Join us at paddle-outs and
          adoption events to engage the community.
        </li>
        <li>
          <strong>Social media highlights:</strong> We’ll spotlight your brand
          on our social channels, showcasing your dedication to helping animals.
        </li>
        <li>
          <strong>Customized opportunities:</strong> Work with us to develop
          creative, targeted campaigns that meet your unique objectives.
        </li>
      </ul>

      <h3 style={styles.subtitle}>Interested in Partnering?</h3>
      <p style={styles.text}>
        We’d love to explore ways we can collaborate to benefit both your
        business and the animals we serve. Please reach out to us at{" "}
        <a href="mailto:info@paddlingpaws.org" style={styles.emailLink}>
          info@paddlingpaws.org
        </a>{" "}
        to discuss partnership opportunities tailored to your specific vision
        and goals.
      </p>

      <h3 style={styles.subtitle}>Our Valued Partners</h3>
      <p style={styles.text}>
        We are deeply thankful for the businesses and organizations who have
        joined us in our mission to create a better life for animals in need.
      </p>

      {/* Partner Logos */}
      <div style={styles.logoContainer}>
        {partnerLogos.map((logo, index) => (
          <div key={index} style={styles.logo}>
            <a href={logo.link} target="_blank" rel="noopener noreferrer">
              <img src={logo.src} alt={logo.alt} style={styles.logoImage} />
            </a>
          </div>
        ))}
        {/* Placeholder for future partners
        <div style={styles.logoPlaceholder}>
          <p>Your Logo Here</p>
        </div> */}
      </div>

      <p style={styles.text}>
        Together, we can make a lasting difference in the lives of countless
        animals. Thank you for considering a partnership with Paddling Paws.
      </p>
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    maxWidth: "800px",
    margin: "0 auto",
  },
  title: {
    fontSize: "32px",
    color: "#5180C0",
    marginBottom: "20px",
  },
  subtitle: {
    fontSize: "24px",
    color: "#5180C0",
    marginTop: "30px",
    marginBottom: "10px",
  },
  text: {
    fontSize: "16px",
    lineHeight: "1.6",
  },
  list: {
    listStyleType: "disc",
    paddingLeft: "20px",
  },
  emailLink: {
    color: "#5180C0",
    textDecoration: "none",
    fontWeight: "bold",
  },
  logoContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "20px",
  },
  logo: {
    margin: "10px",
  },
  logoImage: {
    maxWidth: "200px",
    maxHeight: "150px",
  },
  logoPlaceholder: {
    width: "200px",
    height: "150px",
    border: "2px dashed #ccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
    color: "#777",
    fontSize: "18px",
  },
};

export default Sponsors;
