import React from "react";
// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faYoutube, faAmazon, faFacebook  } from "@fortawesome/free-brands-svg-icons";
import { faCarSide } from "@fortawesome/free-solid-svg-icons";
import sky_profile_img2 from "../assets/images/sky_profile_img2.png";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div style={styles.container}>
      <Helmet>
        <title>Paddling Paws - Home</title>
        <meta
          name="description"
          content="At Paddling Paws, we rescue, rehabilitate, and rehome dogs and cats throughout California."
        />
        <meta
          name="keywords"
          content="animal rescue, dog adoption, cat adoption, California, nonprofit, Central Coast California Animal Rescue, Paddling Paws, dog rescue, cat rescue, dog paddleboarding, cat paddleboarding, paddleboarding with dogs, paddleboarding with cats, paddleboarding with pets, paddleboarding with adoptable rescues, SLO animal rescue, San Luis Obispo animal rescue, SLO dog rescue, San Luis Obispo dog rescue, SLO cat rescue, San Luis Obispo cat rescue, SLO pet adoption, San Luis Obispo pet adoption, SLO animal adoption, San Luis Obispo animal adoption, SLO adoptable dogs, SLO adoptable cats, adopt a kitten in SLO, adopt a puppy in SLO, foster kitten in SLO, foster puppy in SLO, foster a dog on the Central Coast, foster a cat on the Central Coast"
        />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Paddling Paws - Home" />
        <meta
          property="og:description"
          content="Join Paddling Paws in rescuing and rehoming animals throughout California."
        />
        <meta
          property="og:image"
          content="https://paddlingpaws.org/static/media/paddling_paws_hug_2.75ebef04ccbc4c38c2a8.png"
        />
        <meta property="og:url" content="https://www.paddlingpaws.org/" />
        <meta property="og:type" content="website" />

        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {`
                    {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Paddling Paws",
                        "url": "https://www.paddlingpaws.org",
                        "logo": "https://www.paddlingpaws.org/images/logo.png",
                        "sameAs": [
                            "https://www.instagram.com/paddling.paws",
                            "https://www.youtube.com/@paddlingpaws"
                        ],
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "email": "info@paddlingpaws.org",
                            "contactType": "Customer Service"
                        }
                    }
                    `}
        </script>
      </Helmet>
      <div style={styles.imageContainer}>
        <img
          src={sky_profile_img2}
          alt="Paddling Paws volunteers paddleboarding with rescue dogs"
          style={styles.image}
        />
      </div>
      <div style={styles.textContainer}>
        <h2>Welcome to Paddling Paws!</h2>
        <p>
          <strong>
            At Paddling Paws, we are dedicated to helping rescue, rehabilitate,
            and rehome abandoned, neglected, and abused dogs and cats throughout
            California. Our animal rescue organization is based on the Central
            Coast, and we are committed to finding fresh ways to make a
            difference in the lives of animals and the people who love them.
          </strong>{" "}
          Our mission is to provide each animal with the medical care, loving
          foster homes, and adoption opportunities they need to thrive and find
          their forever families.
        </p>

        <p>
          <strong>But our work goes beyond rescue.</strong> We believe in the
          power of community outreach and education to promote animal welfare
          and prevent cruelty. Through our programs, we aim to raise awareness
          and inspire positive change, helping to create a world where every
          animal is treated with compassion.
        </p>

        <p>
          <strong>
            Paddling Paws is proudly recognized as a 501(c)(3) nonprofit
          </strong>{" "}
          (EIN 99-4349426), and your donations are tax-deductible. Every
          contribution helps us save more lives and make a difference in the
          lives of animals in need.
        </p>

        <p>
          <strong>
            We are just getting started October 2024 and are currently trying to
            find support from our community, both near and afar!
          </strong>{" "}
          If you’re passionate about helping animals or want to learn more about
          any of our present or future initiatives, we’d love to hear from you!
          Together, we can make a difference in the lives of countless dogs and
          cats.
        </p>

        {/* This is your MailerLite button */}
        <button
          style={styles.button}
          className="ml-onclick-form"
          onClick={() => {
            if (window.ml) {
              window.ml('show', 'asEY1V', true);
            } else {
              console.error('MailerLite script not found on window');
            }
          }}
        >
          Stay Updated
        </button>
        

        {/* Social Media & Wishlist Links */}
        <div style={styles.socialContainer}>
          <a
            href="https://www.instagram.com/paddling.paws"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.iconButton}
            title="Follow us on Instagram"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61567336580457"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.iconButton}
            title="Follow us on Facebook"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a
            href="https://www.youtube.com/@paddlingpaws"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.iconButton}
            title="Subscribe to our YouTube channel"
          >
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a>
          <a
            // href="https://www.amazon.com/registries/gl/guest-view/37QNF6P0LUXZX"
            href="https://www.amazon.com/registries/gl/guest-view/1EP2GTR90085Y"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.iconButton}
            title="View our Amazon wishlist"
          >
            <FontAwesomeIcon icon={faAmazon} size="2x" />
          </a>
          {/* <a
            href="https://www.bonfire.com/store/paddling-paws/"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.iconButton}
            title="Buy our Merch"
          >
            <FontAwesomeIcon icon={faShoppingCart} size="2x" />
          </a> */}
          <a
            href="https://careasy.org/paddling-paws"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.iconButton}
            title="Donate Car"
          >
            <FontAwesomeIcon icon={faCarSide} size="2x" />
          </a>
          {/* <a
            href="https://venmo.com/u/paddlingpaws"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.iconButton}
            title="Donate via Venmo"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Venmo_logo.svg/2560px-Venmo_logo.svg.png"
              alt="Venmo"
              width="30"
            />
          </a> */}
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "20px",
    flexWrap: "wrap",
  },
  imageContainer: {
    flex: "1",
    minWidth: "300px",
  },
  textContainer: {
    flex: "2",
    marginLeft: "20px",
    minWidth: "300px",
  },
  image: {
    width: "100%",
    maxWidth: "400px",
  },
  button: {
    display: "inline-block",
    padding: "10px 20px",
    backgroundColor: "#5180C0",
    color: "#fff",
    textDecoration: "none",
    fontSize: "16px",
    borderRadius: "5px",
    marginTop: "20px",
    textAlign: "center",
    transition: "background-color 0.3s",
    fontFamily: "KCStrikerSans-Inked",
  },
  socialContainer: {
    display: "flex",
    // justifyContent: 'space-around',
    marginTop: "20px",
  },
  iconButton: {
    color: "#5180C0",
    textDecoration: "none",
    padding: "10px",
    borderRadius: "50%",
    transition: "background-color 0.3s",
  },
  "@media (maxWidth: 768px)": {
    container: {
      flexDirection: "column",
      alignItems: "center",
    },
    textContainer: {
      marginLeft: "0px",
      marginTop: "20px",
    },
  },
};

export default Home;
