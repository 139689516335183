import React from "react";
import ripple from "../assets/images/our_dogs/ripple.JPG";
import ripple_2 from "../assets/images/our_dogs/ripple_2.JPG";
import chiquita from "../assets/images/our_dogs/chiquita.JPG";
import chiquita_2 from "../assets/images/our_dogs/chiquita_3.JPG";
import corgi from "../assets/images/our_dogs/corgi_1.JPG";
import corgi_2 from "../assets/images/our_dogs/corgi_2.JPG";
import koji from "../assets/images/our_dogs/koji_1.JPG";
import koji_2 from "../assets/images/our_dogs/koji_2.JPG";
import rafael from "../assets/images/our_dogs/rafael.JPG";
import rafael_2 from "../assets/images/our_dogs/rafael_2.JPG";
import lolly from "../assets/images/our_dogs/lolly.JPG";
import lolly_2 from "../assets/images/our_dogs/lolly_2.JPG";
import bear from "../assets/images/our_dogs/bear.JPG";
import bear_2 from "../assets/images/our_dogs/bear_2.JPG";
// import misty from "../assets/images/our_dogs/misty_1.JPG";
// import misty_2 from "../assets/images/our_dogs/misty_2.JPG";
import misty_3 from "../assets/images/our_dogs/misty_3.JPG";
import misty_4 from "../assets/images/our_dogs/misty_4.JPG";
import sailor from "../assets/images/our_dogs/sailor_1.JPG";
import sailor_2 from "../assets/images/our_dogs/sailor_2.JPG";
import grom from "../assets/images/our_dogs/grom_1.JPG";
import grom_2 from "../assets/images/our_dogs/grom_2.JPG";

function Animals() {
  // const [animals, setAnimals] = useState([]);
  // const [loading, setLoading] = useState(true);

  // const organizationNames = {
  //   CA3151: "Shiloh's Rescue",
  //   CA3021: "Novy's Ark",
  //   CA3091: "Atlas Rescue",
  // };

  const ourAnimals = [
    {
      src: bear,
      alt: "Bear",
      src2: bear_2,
      alt2: "Bear 2",
      house_trained: true,
      crate_trained: true,
      spayed_neutered: true,
      good_with_dogs: true,
      good_with_children: true,
      name: "Bear",
      species: "Dog",
      description:
        "Bear is a 7-month-old mixed breed who has been waiting patiently in her foster home in Atascadero for over two months, hoping her forever family will finally find her. Rescued from the euthanasia list at Kern County, this girl has already come a long way — and with the right home, she’s ready to blossom even more.\nBear is gentle, affectionate, and good with both kids and dogs — she currently lives with a senior Chihuahua and a large dog and does wonderfully with both. She’s a little unsure of the world, so she’ll thrive in a home that can provide structure, training, and patience as she continues to build confidence and trust.\nWhile she’s full of potential, Bear is still a puppy and learning the ropes — she did unfortunately get into her foster’s chicken enclosure and killed a few chicks. Because of that, Bear needs a home with no small animals (no chickens, cats, or pocket pets, please).\nThis sweet girl is spayed and ready to find her place in the world. She’s not the boldest pup in the pack, but with a kind and consistent adopter by her side, she’s going to shine.\nIf you're ready to guide a truly good dog into her best self, Bear would love to meet you.",
      size: "35 lbs",
      age: "7 months old",
      gender: "Female",
      breed: "Doberman Mix",
      location: "Atascadero, CA",
      email: "adopt@paddlingpaws.org",
      phone: "805-888-9047",
    },
    {
      src: misty_3,
      alt: "Misty",
      src2: misty_4,
      alt2: "Misty 2",
      house_trained: true,
      crate_trained: true,
      spayed_neutered: true,
      good_with_dogs: true,
      good_with_children: true,
      name: "Misty",
      species: "Dog",
      description:
        "Misty is a 50-pound, 1-year-old pitbull mix who’s the perfect blend of playful energy and sweet, snuggly love. Rescued just in time from the euthanasia list at Kern County, this happy girl is now soaking up her second chance at life and is so ready for her forever home!\nAlready spayed and fully potty trained, Misty is eager to please and even more eager to love. Her days are filled with bounding around the yard like a little sunbeam, followed by cuddles and calm companionship. She’s done amazing with nail trims, baths, car rides, and even paddleboarding — seriously, what can’t this girl do?\nMisty is currently enrolled in a month-long board and train program in Paso Robles, where she’s learning all the skills to become the most adoptable, well-mannered gal around. Even better? Her adopters will receive FREE lifelong training support from her trainer to ensure a smooth transition and continued success.\nWhile Misty thrives with people, she’d do best in a home with either no other dogs or a respectful male dog her size or larger.\nWith her wagging tail greetings, joyful zoomies, and cozy snuggle sessions, Misty is always looking for ways to make you smile. Could you be the happy ending she’s been waiting for?",
      size: "50 lbs",
      age: "1 year old",
      gender: "Female",
      breed: "Pit-bull Mix",
      location: "Paso Robles, CA",
      email: "adopt@paddlingpaws.org",
      phone: "805-888-9047",
    },
    {
      src: sailor,
      alt: "Sailor",
      src2: sailor_2,
      alt2: "Sailor 2",
      house_trained: true,
      crate_trained: true,
      spayed_neutered: false,
      good_with_dogs: true,
      good_with_cats: true,
      good_with_children: true,
      name: "Sailor",
      species: "Dog",
      description:
        "Say hello to Sailor, a 3-4 month old female pitbull mix who’s already stealing hearts wherever she goes! This gorgeous little pup has the sweetest soul and the chillest vibe — seriously, she’s a total gem. Whether she’s lounging at home or out on the water, Sailor is as calm and well-behaved as they come for a young pup.\nSailor recently joined us for our paddle-out event and handled it like a total pro — she was happy to be held, hop on any paddleboard, and soak up all the love and attention. She’s friendly with everyone, has been doing great with socialization, and we’re making amazing progress with potty training!\nShe’s not spayed yet, but don’t worry — Paddling Paws will cover the cost of her spay surgery when she’s mature and ready. Per our adoption contract, we do require that Sailor be spayed by the time she’s one year old.\nIf you’re looking for a sweet, smart, and social pup to be your best friend on land and sea, Sailor is your girl. Come meet her and see for yourself — she’s ready to sail into your heart and home. 🐶💕",
      size: "25 lbs",
      age: "3 months old",
      gender: "Female",
      breed: "Pit-bull Mix",
      location: "Atascadero, CA",
      email: "adopt@paddlingpaws.org",
      phone: "805-888-9047",
    },
    {
      src: grom,
      alt: "Grom",
      src2: grom_2,
      alt2: "Grom 2",
      crate_trained: false,
      spayed_neutered: false,
      good_with_dogs: true,
      good_with_cats: true,
      good_with_children: true,
      name: "Grom",
      species: "Dog",
      description:
        "Introducing Grom (aka Little Grom), an 8-week-old female Lab mix rescued from Avenal Animal Services. Weighing in at just 7 pounds, this tiny powerhouse is already proving she’s got a big heart and an even bigger zest for life!\nGrom is spunky, smart, and ready for anything — whether it’s kayaking, being passed around like a beach day celebrity, or exploring new places with a wagging tail and fearless spirit. She's the kind of pup who adapts quickly and thrives on love, adventure, and good vibes.\nShe’s still a baby, so she’s not spayed yet, but don’t worry — Paddling Paws covers the cost of her spay when she’s older. As with all our adoptions, we do require she be spayed by 1 year of age.\nIf you're looking for a bold little explorer who will grow into your ultimate ride-or-die companion, Little Grom is your girl. She’s got the brains, the bravery, and all the cuteness you could ever want.",
      size: "7 lbs",
      age: "2 months old",
      gender: "Female",
      breed: "Lab Mix",
      location: "Los Osos, CA",
      email: "adopt@paddlingpaws.org",
      phone: "805-888-9047",
    },
    // Add more animals here
  ];

  const ourAdoptedAnimals = [
    {
      src: ripple,
      alt: "Ripple",
      src2: ripple_2,
      alt2: "Ripple 2",
      house_trained: true,
      spayed_neutered: false,
      good_with_dogs: true,
      good_with_cats: true,
      good_with_children: true,
      name: "Ripple",
      new_name: "Riley Hobbes",
      species: "Dog",
      description:
        "Ripple is an affectionate and playful pup with a heart full of love! She adores giving kisses, snuggling up for cuddles, and enjoys being around people of all ages. Ripple gets along wonderfully with other dogs, cats, and children, making her a perfect companion for any family. She’s house-trained, well-behaved, and even loves adventures like paddleboarding or playing at the beach. Rescued from a high-kill shelter in Kern County after being found as a stray with her baby, Ripple has overcome a challenging start and is now ready to find her forever home. Could you be the one to give Ripple the loving family she deserves?",
      size: "33 lbs",
      age: "1.5 years old",
      gender: "Female",
      breed: "Terrier Mix",
      location: "Atascadero, CA",
      email: "info@paddlingpaws.org",
      phone: "805-888-9047",
    },
    {
      src: chiquita,
      alt: "Chiquita",
      src2: chiquita_2,
      alt2: "Chiquita 2",
      house_trained: true,
      spayed_neutered: true,
      good_with_dogs: true,
      good_with_cats: true,
      good_with_children: true,
      name: "Chiquita",
      new_name: "Chikorita",
      species: "Dog",
      description:
        "Rescued from Kern County shelter, Chiquita is a sweet and gentle soul ready to find her forever family! This small-to-medium-sized beauty is the perfect package of love and charm, with baby puppy-dog eyes that will melt your heart instantly. Chiquita is fantastic in the car and gets along wonderfully with everyone—she’s great with cats, dogs, and kids alike. Though she may be a little shy at first, her sweetness shines through as soon as she feels comfortable. Her gentle nature makes her an ideal companion for families or anyone looking for a calm and loving furry friend. If you’re searching for a quiet, affectionate, and oh-so-cute dog to complete your family, Chiquita could be the one! Apply to meet Chiquita today—she can’t wait to meet you!",
      size: "26 lbs",
      age: "1.5 years old",
      gender: "Female",
      breed: "Terrier Mix",
      location: "Los Osos, CA",
      email: "info@paddlingpaws.org",
      phone: "805-888-9047",
    },
    {
      src: corgi,
      alt: "Miso",
      src2: corgi_2,
      alt2: "Miso 2",
      house_trained: true,
      spayed_neutered: true,
      good_with_dogs: true,
      good_with_cats: false,
      good_with_children: true,
      name: "Miso",
      new_name: "Coco",
      species: "Dog",
      description:
        "This 5-month-old bundle of joy is the definition of fun-sized happiness! With her adorably short legs, long body, and endless puppy energy, she’s a walking ray of sunshine. Playful, spunky, and always ready for an adventure, she’s fantastic with dogs of all sizes and ages and handles even the most energetic kids like a pro. Whether she’s zooming around the yard or snuggling up after a day of fun, her sweet and spirited personality shines through. If you’re looking for a vertically-challenged pup with a heart full of love and a tail that never stops wagging, she’s your girl!",
      size: "20 lbs",
      age: "5 months old",
      gender: "Female",
      breed: "Corgi Mix",
      location: "Atascadero, CA",
      email: "info@paddlingpaws.org",
      phone: "805-888-9047",
    },
    {
      src: koji,
      alt: "Koji",
      src2: koji_2,
      alt2: "Koji 2",
      house_trained: false,
      spayed_neutered: true,
      good_with_dogs: true,
      good_with_cats: true,
      good_with_children: true,
      name: "Koji",
      new_name: "Charlie",
      species: "Dog",
      description:
        "This sweet 4-month-old boy was rescued from Kern County just days before he was scheduled to be euthanized, and he’s been melting hearts ever since. With his gentle nature and shy but loving personality, Koji is the perfect companion for a calm and caring home. He’s fantastic with dogs of all sizes, friendly with cats, and wonderfully gentle with children. Whether he’s cuddled up beside you or making new furry friends, this little guy’s tender spirit shines through. If you're looking for a loyal, loving pup to complete your family, Koji is ready to steal your heart!",
      size: "20 lbs",
      age: "4 months old",
      gender: "Male",
      breed: "Large Breed Mix",
      location: "Los Osos, CA",
      email: "info@paddlingpaws.org",
      phone: "805-888-9047",
    },
    {
      src: rafael,
      alt: "Rafael",
      src2: rafael_2,
      alt2: "Rafael 2",
      house_trained: false,
      spayed_neutered: false,
      good_with_dogs: true,
      good_with_cats: true,
      good_with_children: true,
      name: "Rafael",
      new_name: "Diesel",
      species: "Dog",
      description:
        "Say hello to Rafael, a 7-week-old pitbull puppy with a heart as big as his personality! Found on San Rafael Rd in Atascadero, it’s hard to believe this little guy was abandoned in an Amazon box. But Rafael isn’t letting his rough start hold him back—he’s all about fun, love, and endless puppy energy. Weighing in at just 9 pounds, Rafael has no problem keeping up with his foster family's 85 lb Boxer buddy. Whether it’s zooming around the yard, playfully wrestling, or snuggling up after a big day of adventures, Rafael tackles life with a fearless spirit and a wagging tail. This little guy is as sweet as he is spunky, and he's ready to find a forever home where he can continue to grow, play, and share his boundless love. Could Rafael be your new best friend? 🏡 ❤️ 👉 Apply to adopt Rafael today and give this brave little survivor the loving home he deserves!",
      size: "9 lbs",
      age: "7 weeks old",
      gender: "Male",
      breed: "Pitbull",
      location: "Atascadero, CA",
      email: "info@paddlingpaws.org",
      phone: "805-888-9047",
    },
    {
      src: lolly,
      alt: "Lolly",
      src2: lolly_2,
      alt2: "Lolly 2",
      house_trained: true,
      spayed_neutered: true,
      good_with_dogs: true,
      good_with_cats: false,
      good_with_children: true,
      name: "Lolly",
      species: "Dog",
      description:
        "Meet Lolly! This beautiful 1-year-old, 35 lb shepherd mix was rescued from Lancaster just last week, where she narrowly escaped being euthanized due to overcrowding. Now safe and ready for her forever home, Lolly is an incredible medium-sized dog with so much love to give!\nLolly is a super sweet and smart girl who sometimes feels a little unsure with new experiences, but she handles them like a champ! She recently took her first trip to the beach and even tried paddleboarding in Morro Bay—and she was a total pro! Her calm demeanor, good manners, and eagerness to please make her a joy to be around.\nShe gets along well with other dogs but wouldn’t be a good fit for a home with cats. If you’re looking for a loyal, medium-sized companion to share adventures and cuddle time, Lolly might be the perfect match! Apply to adopt Lolly today! She’s ready to find the loving home she truly deserves!",
      size: "35 lbs",
      age: "1 year old",
      gender: "Female",
      breed: "Border Collie-Sheperd Mix",
      location: "Atascadero, CA",
      email: "info@paddlingpaws.org",
      phone: "805-888-9047",
    },
    // Add more animals here
  ];

  // useEffect(() => {
  //   const fetchAnimals = async () => {
  //     try {
  //       const organizationIds = "CA3021,CA3151,CA3091";
  //       const response = await fetch(
  //         `https://api.petfinder.com/v2/animals?organization=${organizationIds}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${process.env.REACT_APP_PETFINDER_ACCESS_TOKEN}`,
  //           },
  //         }
  //       );
  //       const data = await response.json();
  //       setAnimals(data.animals);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error("Error fetching animals:", error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchAnimals();
  // }, []);

  return (
    <div style={{ padding: "20px" }}>
      <h2>Our Available Animals</h2>
      <p>
        These animals are available for adoption through Paddling Paws. If you
        are interested in adopting one of our animals, please fill out our{" "}
        <a href="/adopt">Adoption Application</a>.
      </p>

      {ourAnimals.length === 0 ? (
        <p style={{ marginTop: "1rem" }}>
          There are currently no animals available for adoption. However, we
          encourage you to fill out our Adoption Application and let us know
          what type of pet you’re searching for. We’ll work diligently to help
          you find and rescue the perfect companion.
        </p>
      ) : (
        <div style={styles.gridContainer}>
          {ourAnimals.map((animal) => (
            <div key={animal.name} style={styles.gridItem}>
              <h3>{animal.name}</h3>
              <p>{animal.species}</p>
              <img src={animal.src} alt={animal.alt} style={styles.image} />
              <p>
                <strong>Gender:</strong> {animal.gender}
              </p>
              <p>
                <strong>Size:</strong> {animal.size}
              </p>
              <p>
                <strong>Age:</strong> {animal.age}
              </p>
              <p>
                <strong>Breed:</strong> {animal.breed}
              </p>
              <p>
                <strong>Location:</strong> {animal.location}
              </p>
              {animal.house_trained && <p>✔ Housetrained</p>}
              {animal.crate_trained && <p>✔ Crate trained</p>}
              {animal.spayed_neutered && (
                <p>✔ {animal.gender === "Female" ? "Spayed" : "Neutered"}</p>
              )}
              {animal.good_with_dogs && <p>✔ Good with other dogs</p>}
              {animal.good_with_cats && <p>✔ Good with cats</p>}
              {animal.good_with_children && <p>✔ Good with children</p>}
              <img src={animal.src2} alt={animal.alt2} style={styles.image} />
              {/* <p>{animal.description}</p> */}
              {animal.description.split("\n").map((line, index) => (
                <p key={index}>{line}</p>
              ))}
              <p>
                <strong>If Interested:</strong>
              </p>
              <p>
                Apply to adopt {animal.name} with our{" "}
                <a href="/adopt" target="_blank" rel="noopener noreferrer">
                  Adoption Application
                </a>
              </p>
            </div>
          ))}
        </div>
      )}

      <h2>Our Adopted Rescues</h2>
      <p>
        These animals are past Paddling Paws rescues that have since found their
        forever homes. To continue to follow their journey, check out our
        Facebook group,{" "}
        <a
          href="https://www.facebook.com/groups/1291072548697671/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Adopted & Loved
        </a>
        , to see their life after rescue.
      </p>
      <div style={styles.gridContainer}>
        {ourAdoptedAnimals.map((animal) => (
          <div key={animal.name} style={styles.gridItem}>
            {animal.new_name ? <h3>
              Adopted! {animal.name}, now named {animal.new_name || animal.name}!
            </h3> : <h3>Adopted! {animal.name}!</h3>}
            <p>Adopted {animal.species}</p>
            <img src={animal.src} alt={animal.alt} style={styles.image} />
            <p>
              <strong>
                Woo hoo! {animal.gender === "Female" ? "She" : "He"} is Adopted!
              </strong>
            </p>
            <p>
              <strong>Gender:</strong> {animal.gender}
            </p>
            <p>
              <strong>Size:</strong> {animal.size}
            </p>
            <p>
              <strong>Age:</strong> {animal.age}
            </p>
            <p>
              <strong>Breed:</strong> {animal.breed}
            </p>
            <p>
              <strong>Location:</strong> {animal.location}
            </p>
            {animal.house_trained && <p>✔ Housetrained</p>}
            {animal.spayed_neutered && (
              <p>✔ {animal.gender === "Female" ? "Spayed" : "Neutered"}</p>
            )}
            {animal.crate_trained && <p>✔ Crate trained</p>}
            {animal.good_with_dogs && <p>✔ Good with other dogs</p>}
            {animal.good_with_cats && <p>✔ Good with cats</p>}
            {animal.good_with_children && <p>✔ Good with children</p>}
            <img src={animal.src2} alt={animal.alt2} style={styles.image} />
            <p>
              <strong>
                Yay! {animal.gender === "Female" ? "She" : "He"} is Adopted!
              </strong>
            </p>
            {animal.description.split("\n").map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </div>
        ))}
      </div>

      {/* <h2>
        Available Animals from Other Local Organizations on the Central Coast
      </h2>
      <p>
        If you are interested in any of the pets below, please reach out
        directly to these rescues for steps to apply to adopt.
      </p>
      {loading ? (
        <p>Loading animals...</p>
      ) : (
        <div style={styles.gridContainer}>
          {animals.length > 0 ? (
            animals.map((animal) => (
              <div key={animal.id} style={styles.gridItem}>
                <h3>{animal.name}</h3>
                <p>{animal.species}</p>
                {animal.photos.length > 0 && (
                  <img
                    src={animal.photos[0]?.medium}
                    alt={animal.name}
                    style={styles.image}
                  />
                )}
                <p>
                  <strong>Organization:</strong>{" "}
                  {organizationNames[animal.organization_id]}
                </p>
                <p>{animal.description}</p>
                <p>
                  <strong>Gender:</strong> {animal.gender}
                </p>
                <p>
                  <strong>Size:</strong> {animal.size}
                </p>
                <p>
                  <strong>Age:</strong> {animal.age}
                </p>
                <p>
                  <strong>Breed:</strong> {animal.breeds.primary}
                </p>
                <p>
                  <strong>Location:</strong> {animal.contact.address.city},{" "}
                  {animal.contact.address.state}
                </p>
                {animal.attributes.house_trained && <p>✔ Housetrained</p>}
                {animal.attributes.spayed_neutered && <p>✔ Spayed/Neutered</p>}
                {animal.environment.dogs && <p>✔ Good with other dogs</p>}
                {animal.environment.cats && <p>✔ Good with cats</p>}
                {animal.environment.children && <p>✔ Good with children</p>}
                {animal.contact.email && (
                  <p>
                    <strong>Email:</strong> {animal.contact.email}
                  </p>
                )}
                {animal.contact.phone && (
                  <p>
                    <strong>Phone:</strong> {animal.contact.phone}
                  </p>
                )}
              </div>
            ))
          ) : (
            <p>No animals available at the moment.</p>
          )}
        </div>
      )} */}
    </div>
  );
}

const styles = {
  gridContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginTop: "20px",
  },
  gridItem: {
    flex: "1 1 calc(33.333% - 20px)",
    boxSizing: "border-box",
    margin: "10px",
    padding: "10px",
    border: "3px solid #5180C0",
    borderRadius: "8px",
    maxWidth: "300px",
    minWidth: "200px",
    lineHeight: "1.5em", // Added for consistent text spacing
  },
  image: {
    width: "100%",
    height: "auto",
    borderRadius: "8px",
    marginBottom: "10px", // Added for consistent spacing under images
  },
};

export default Animals;
