import React from "react";
import PurpleWitchesPaddleEvent from "../assets/images/events/PurpleCutePetHalloweenPartyInvitationPoster.png";
import New_Year_Beach_Walk from "../assets/images/events/New_Year_Beach_Walk.png";
import march_april from "../assets/images/events/events_march_april.png";
import Pizza_Night_April_9 from "../assets/images/events/Pizza_Night_April_9.png";
import Paddle_Out_March_22 from "../assets/images/events/Retro_Paddle_Out_Poster_3_22.png";

const events = [
  {
    id: 5,
    title: "Paddle Out with Rescue Pups!",
    date: "3/22/25",
    time: "10am - 1pm",
    location: "Coleman Park Beach, Morro Bay",
    image: Paddle_Out_March_22,
    url: "https://enjoyslo.com/events/paddle-out-with-rescue-pups/",
    notes:
      "🌊 Paddle with Pups – Free Community Event!\nJoin us for a free paddle out featuring some of our adoptable rescue pups! Whether you bring your own paddleboard or kayak or borrow one of ours, this is the perfect way to enjoy the water, meet our amazing dogs, and connect with fellow animal lovers.\n🐶 Bring Your Own Dog! Friendly, water-loving pups are welcome to join in on the fun.\n🏄 Beginner-Friendly! Never paddled before? No worries! This event is open to all experience levels and the Paddling Paws team will be there to support you.\n☀️ Open to the Public – Come soak up the fresh air, ride the waves, and maybe even meet your new best friend!\nWe can’t wait to see you on the water! 🌊🐾",
  },
  {
    id: 4,
    title: "Pupperoni Pizza Night with Funk Junket",
    date: "4/9/25",
    time: "5pm - 8pm",
    location: "Bread Bike Bakery, 2060 Parker St, San Luis Obispo, CA 93401",
    image: Pizza_Night_April_9,
    url: "https://enjoyslo.com/events/%f0%9f%8d%95-pupperoni-pizza-night-at-bread-bike-%f0%9f%8e%b6%f0%9f%90%be/",
    notes:
      "🍕 Pupperoni Pizza Night at Bread Bike! 🎶🐾\nGet ready for a night of delicious pizza, live music, and adoptable pups at Pupperoni Pizza Night! 🍕🐶 Hosted by Bread Bike and benefiting Paddling Paws, this event is the perfect way to enjoy good food, great vibes, and even better company.\n🎵 Live Music by The Funk Junket – Get your groove on while supporting a great cause!\n🐶 Meet Our Adoptable Rescue Dogs – Your new best friend could be waiting for you!\n🍕 Support a Local Business & Nonprofit – A portion of pizza sales will go directly to Paddling Paws to help save more animals in need.\nBring your friends, family, and fellow animal lovers for a fun-filled night of music, food, and furry friends! Whether you're here for the pizza, the pups, or the party, you won’t want to miss this event!🐾",
  },
  {
    id: 3,
    title: "Upcoming Events for March & April",
    date: "3/22/25, 4/9/25, 4/26/25",
    time: "Varies, As Listed Above.",
    location: "Varies, As Listed Above.",
    image: march_april,
    notes:
      "We have a lot of exciting events coming up in March and April. Check out the flyer above and follow us for more details! We are currently planning multiple paddle out events and local collabs with amazing businesses! We are particularly excited for our April collab with Bread Bike for our Pupperoni Pizza Night with live music from the Funk Junket! Stay tuned for more details!",
  },
  {
    id: 2,
    title: "New Year's Beach Walk",
    date: "January 4th, 2025",
    time: "10:30 AM - 12:30 PM",
    location: "End of Grand Ave, Grover Beach",
    image: New_Year_Beach_Walk,
    url: "https://www.facebook.com/events/1211900743235292/",
    notes:
      "Join us for a New Year's Beach Walk to celebrate the start of 2025. We will meet at the end of Grand Ave in Grover Beach and walk along the beach. Dogs are welcome, but must be on a leash. Free snacks and beverages will be provided after the walk!",
  },
  {
    id: 1,
    title: "Witches and Warlocks Paddle",
    date: "October 26th, 2024",
    time: "12:00 PM - 4:00 PM",
    location: "Coleman Park, Morro Bay",
    image: PurpleWitchesPaddleEvent,
  },
];

function Events() {
  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Upcoming Events</h2>
      <div style={styles.eventList}>
        {events.map((event) => (
          <div key={event.id} style={styles.eventItem}>
            <img
              src={event.image}
              alt={event.title}
              style={styles.eventImage}
            />
            <h3 style={styles.eventTitle}>{event.title}</h3>
            <p style={styles.eventDate}>📅 Date: {event.date}</p>
            {event.time && (
              <p style={styles.eventTime}>
                <strong>⏰ Time:</strong> {event.time}
              </p>
            )}
            {event.location && (
              <p style={styles.eventLocation}>
                <strong>📍 Location:</strong> {event.location}
              </p>
            )}
            {event.url && (
              <p style={styles.eventLocation}>
                <a href={event.url}>Event Link</a>
              </p>
            )}
            {event.notes &&
              event.notes
                .split("\n")
                .map((line, index) => <p key={index}>{line}</p>)}
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    padding: "20px",
    maxWidth: "1200px",
    margin: "0 auto",
  },
  heading: {
    textAlign: "center",
    marginBottom: "20px",
  },
  eventList: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    maxHeight: "600px",
    paddingRight: "10px",
  },
  eventItem: {
    marginBottom: "20px",
    borderBottom: "1px solid #ccc",
    paddingBottom: "10px",
  },
  eventImage: {
    width: "100%",
    maxWidth: "600px",
    height: "auto",
    display: "block",
    margin: "0 auto",
  },
  eventTitle: {
    fontSize: "1.5rem",
    textAlign: "center",
    marginTop: "10px",
  },
  eventDate: {
    fontSize: "1.2rem",
    textAlign: "center",
  },
  eventTime: {
    fontSize: "1rem",
    textAlign: "center",
  },
  eventLocation: {
    fontSize: "1rem",
    textAlign: "center",
  },
};

export default Events;
